import Container from "react-bootstrap/Container";
import Image from "next/image";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
// PICTURES
import picture404 from "@/public/static/images/404.png";

export default function Custom404() {
  const { t } = useTranslation("common");

  return (
    <Container style={{ height: "60vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <div className="px-3 d-flex flex-column align-items-center">
        <div className="px-3">
          <Image src={picture404} alt="404" width={485} height={350} quality={50} />
        </div>
        <div className="py-4 d-flex flex-column align-items-center align-text-center">
          <h2 className="fw-semibold">{t("404.not-found")}</h2>
        </div>
      </div>
    </Container>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common", "header", "footer"])),
    },
  };
}
